import React, {Component} from "react";
import {Col, Grid, Row, Table} from "react-bootstrap";
import Card from "../../components/Card/Card";
import Loader from "../../components/Loader/Loader";
import {API} from "aws-amplify";
import {toTitleCase} from "../../utils/Utils";
import ReactJson from "react-json-view";
import FormControl from "react-bootstrap/lib/FormControl";

export default class UserPaymentInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            payment_info: {},
            isLoading: true,
        }
    }

    componentDidMount() {
        let user_id = this.props.location.state.user_id

        API.get('admin', `/admin/client-users/${user_id}/payment-info`)
            .then(data => {
                this.setState({
                    payment_info: data,
                    isLoading: false,

                })
            }).catch(error => {
            this.setState({
                isLoading: false,

            })
            console.error(error)
        })
    }

    render() {
        let info = this.state.payment_info || {}
        let user_id = this.props.location.state.user_id

        return (
            <div className="content">
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                title={`Payment info for user ${user_id}`}
                            />
                            <Card
                                ctTableResponsive
                                content={
                                    <Loader isLoading={this.state.isLoading}>
                                        {Object.keys(info).length === 0 ?
                                            <p>No user payment info</p> :
                                            <Table striped hover bordered>
                                                <thead>
                                                <tr>
                                                    <th className="col-md-2">Key</th>
                                                    <th>Value</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    Object.keys(info).map((key, index) => {
                                                        return <tr key={index}>
                                                            <td>
                                                                <strong>{toTitleCase(key)}</strong>
                                                            </td>
                                                            <td>{key === "history" ?
                                                                <ReactJson
                                                                    displayDataTypes={false}
                                                                    sortKeys
                                                                    collapsed={1}
                                                                    src={info[key]}/> :
                                                                <FormControl
                                                                    rows={key === "receipt" ? 15 : 1}
                                                                    readOnly
                                                                    componentClass="textarea"
                                                                    as="textarea"
                                                                    value={info[key] || ""}/>}
                                                            </td>
                                                        </tr>
                                                    })
                                                }
                                                </tbody>
                                            </Table>}
                                    </Loader>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>

            </div>
        );
    }
}
