export default {
  "API_KEY": "IgnPXNFUyZ14t3eQfBzSr6FefafEAjPX9Tuk3lNz",
  "API_URL": "https://scanner-api-admin-stage.asqq.io",
  "COGNITO_APP_CLIENT_ID": "22akcpbbq0k03pvnhgrb9qpk0",
  "COGNITO_DOMAIN": "scanner-api-admin-stage.auth.eu-central-1.amazoncognito.com",
  "COGNITO_REDIRECT_SIGN_IN": "https://scanner-admin-stage.asqq.io/",
  "COGNITO_REDIRECT_SIGN_OUT": "https://scanner-admin-stage.asqq.io/",
  "COGNITO_USER_POOL_ID": "eu-central-1_snWgqvOVh",
  "REGION": "eu-central-1"
}
