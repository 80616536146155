export default {
    app: {
        name: "Scanner"
    },
    userGroups: {
        admin: "admin",
        viewer: "viewer",
        editor: "editor",
        support: "support",
    }
};
