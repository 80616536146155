import defaults from "./defaults";
import Info from "./controllers/Info";
import UserPaymentInfo from "./controllers/User/UserPaymentInfo";
import User from "./controllers/User/User";

let g = defaults.userGroups

const dashboardRoutes = [
    // Users
    {
        exact: true,
        path: "/users",
        name: "Users",
        icon: "pe-7s-user",
        component: User,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer, g.support]
    },
    {
        invisible: true,
        path: "/users/:id/payment-info",
        name: "User Payment Info",
        icon: "pe-7s-user",
        component: UserPaymentInfo,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer, g.support]
    },
    {
        path: "/info",
        name: "Info",
        icon: "pe-7s-info",
        component: Info,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer, g.support]
    },
];

export default dashboardRoutes;
