import React, {Component} from "react";
import List from "../../components/List/List";
import {UserFilter} from "../../models/User";
import {NavLink} from "react-router-dom";
import Button from "react-bootstrap/lib/Button";
import defaults from "../../defaults";

let g = defaults.userGroups

class User extends Component {
    constructor(props) {
        super(props);
        this.editPath = '/admin/users/edit';
    }

    render() {
        return (
            <List
                name="Users"
                editPath={this.editPath}
                removeUrl="/admin/client-users"
                listApiUrl='/admin/client-users'
                actions={[
                    (list, data_) => {
                        return <Button
                            allowedGroups={[g.admin, g.editor]}
                            className="btn btn-fill btn-danger btn-xs"
                            onClick={(e) => list.handleRemove(data_.id, e)}>
                            <i className="fa fa-times"/>
                        </Button>
                    },
                    (list, data) => {
                        return <NavLink
                            to={{
                                pathname: `users/${data.id}/payment-info`,
                                state: {user_id: data.id}
                            }}
                            className="btn btn-fill btn-success btn-xs"
                        >
                            <i className="fa fa-dollar"/>
                        </NavLink>
                    }
                ]}
                model={[
                    {col: 'Id', row: 'id'},
                    {col: 'Email', row: 'email'},
                    {col: 'Name', row: 'name'},
                    {
                        col: 'Is Paid', row: 'is_paid', normalizer: (data) => {
                            return data.is_paid ? 'Yes' : 'No'
                        }
                    },
                    {col: 'Locale', row: 'locale'},
                    {col: 'Timezone', row: 'timezone'},
                    {col: 'Created At', row: 'created_at'},
                    {col: 'Client Version', row: 'client_version'},
                    {
                        col: 'Problem Zones',
                        row: 'problem_zones',
                        normalizer: (data) => {
                            return data.problem_zones ? data.problem_zones.join(', ') : '-'
                        }
                    },
                    {col: 'Fitness Level', row: 'fitness_level'},
                    {col: 'Goal', row: 'goal'},
                    {col: 'Weight', row: 'weight'},
                    {col: 'Height', row: 'height'},
                    {col: 'Platform', row: 'platform'},
                    {
                        col: 'Is password set', row: 'is_password_set',
                        normalizer: (data) => {
                            return data.is_password_set ? 'Yes' : 'No'
                        }
                    },
                ]}
                onRemove={this.onRemove}
                handleClick={this.props.handleClick}
                userHasPermission={this.props.userHasPermission}
                filterObject={UserFilter}
            >
            </List>

        );
    }
}

export default User;
