import React, {Component} from "react";
import {Col, ControlLabel, FormControl, FormGroup, Row} from "react-bootstrap";
import Select from "react-select";
import AsyncSelect from 'react-select/async';
import makeAnimated from 'react-select/animated';
import {toTitleCase} from "../../utils/Utils";
import TableCollection from "./TableCollection";
import Collection from "./Collection";

const TYPES = {
    FormControl: 'input',
    Select: 'select',
    MultiSelect: 'multiSelect',
    Collection: 'collection',
    TableCollection: 'table_collection',
};

const animatedComponents = makeAnimated();

export class FormRow extends Component {
    render() {
        var label;
        var field;
        var validator;

        if (this.props.label !== false) {
            label = <ControlLabel>{toTitleCase(this.props.name)}</ControlLabel>
        }

        if (this.props.type === TYPES.Select || this.props.type === TYPES.MultiSelect) {
            let genericProps = {
                placeholder: toTitleCase(this.props.placeholder || this.props.name),
                isClearable: true,
                name: this.props.name,
                value: this.props.value,
                components: animatedComponents,
                onChange: this.props.onChangeEvent,
                isMulti: this.props.type === TYPES.MultiSelect,
                closeMenuOnSelect: this.props.type !== TYPES.MultiSelect,
                ...(this.props.selectProps || {})
            }
            if ((this.props.selectProps || {}).loadOptions !== undefined) {
                field = <AsyncSelect
                    defaultOptions={this.props.value ? [this.props.value] : true}
                    {...genericProps}
                />
            } else {
                field = <Select
                    options={this.props.selectOptions}
                    isLoading={this.props.selectOptions.length === 0}
                    {...genericProps}
                />
            }
        } else if (this.props.type === TYPES.TableCollection) {
            field = <TableCollection
                name={this.props.name}
                prototype={this.props.prototype}
                value={this.props.value}
                onChange={this.props.onChangeEvent}
                validator={this.props.validator}
            />
        } else if (this.props.type === TYPES.Collection) {
            field = <Collection
                name={this.props.name}
                prototype={this.props.prototype}
                value={this.props.value}
                onChange={this.props.onChangeEvent}
                validator={this.props.validator}
            />
        } else {
            field = <FormControl
                type={this.props.inputType || "text"}
                name={this.props.name}
                value={this.props.value}
                onChange={this.props.onChangeEvent}
                placeholder={toTitleCase(this.props.placeholder || this.props.name)}
                {...(this.props.selectProps || {})}
            />
        }
        if (this.props.validator !== undefined && this.props.validationRules !== '') {
            validator = this.props.validator.message(this.props.name, this.props.value, this.props.validationRules)
        }

        return (
            <Row>
                <Col md={this.props.md || 12}>
                    <FormGroup>
                        {label}
                        {field}
                        {validator}
                    </FormGroup>
                </Col>
            </Row>
        )
    }
}

export default FormRow;
